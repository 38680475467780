export function mobileGallery( offsetTop, smoothScroll ) {
	//Mobile Gallery
	var mobile_gallery = document.querySelector( '.project-gallery-mobile' );
	var open_gallery = document.querySelector( '#js-mobile-gallery-button' );
	var main_image = document.querySelector( '.project-gallery-mobile__main-img' );
	var gallery_images = document.querySelectorAll( '.project-gallery-mobile__reveal' );
	var gallery_container = document.querySelector( '#js-mobile-gallery-container' );
	var offset_top = 0;

	//Prepare
	prepareGallery();
	//Check for only horizontal resize
	var window_width = window.innerWidth;
	window.addEventListener( 'resize', function() {
		var new_window_width = window.innerWidth;
		if ( window_width != new_window_width ) {
			prepareGallery();
		}
	} );

	window.addEventListener( 'scroll', function() {
		//Sticky close button
		if ( mobile_gallery.classList.contains( 'open' ) ) {
			var gallery_offset = offsetTop( gallery_container ) + gallery_container.offsetHeight;
			if ( window.pageYOffset + window.innerHeight >= gallery_offset || window.pageYOffset + window.innerHeight <= offsetTop( gallery_container ) + 70 ) {
				open_gallery.classList.remove( 'sticky' );
			} else {
				open_gallery.classList.add( 'sticky' );
			}
		}
	} );

	//Open/Close
	open_gallery.addEventListener( 'click', function() {
		if ( mobile_gallery.classList.contains( 'open' ) ) {
			mobile_gallery.classList.remove( 'open' );

			scrollToTop( 400 );

			window.setTimeout( function() {
				open_gallery.classList.remove( 'sticky' );
				for ( var i = gallery_images.length - 1; i >= 0; i-- ) {
					closeGallery( i );
				}
			}, 200 );
		} else {
			mobile_gallery.classList.add( 'open' );
			mobile_gallery.style.height = 'auto';
			gallery_container.style.marginTop = 0;

			scrollToTop( 200 );

			window.setTimeout( function() {
				for ( var i = 0; i < gallery_images.length; i++ ) {
					openGallery( i );
				}
			}, 150 );
		}
	} );

	gallery_container.addEventListener( 'click', function() {
		if ( mobile_gallery.classList.contains( 'open' ) ) {
			mobile_gallery.classList.remove( 'open' );

			scrollToTop( 400 );

			window.setTimeout( function() {
				open_gallery.classList.remove( 'sticky' );
				for ( var i = gallery_images.length - 1; i >= 0; i-- ) {
					closeGallery( i );
				}
			}, 200 );
		}
	} );

	function scrollToTop( int ) {
		//Smoothscroll
		var target_y = offsetTop( main_image );
		if ( window.pageYOffset != target_y ) {
			smoothScroll.scroll( target_y, int );
		}
	}

	function prepareGallery() {
		if ( mobile_gallery.classList.contains( 'open' ) ) {
			return;
		}
		mobile_gallery.style.height = main_image.offsetHeight + 'px';
		main_image.style.zIndex = gallery_images.length + 1;
		gallery_container.style.marginTop = - main_image.offsetHeight + 'px';
		for ( var i = 0; i < gallery_images.length; i++ ) {
			gallery_images[i].style.marginTop = - offset_top + 'px';
			gallery_images[i].style.zIndex = gallery_images.length - i;
			offset_top = gallery_images[i].offsetHeight;
		}
	}

	function openGallery( i ) {
		if ( i <= 5 ) {		//Make things faster
			setTimeout( function() {
				showImage( gallery_images[i] );
				//smoothscroll
				var target_y = offsetTop( main_image );
				window.scrollTo( 0, target_y );
			}, ( i * 100 ) );
		} else {
			setTimeout( function() {
				showImage( gallery_images[i] );
			}, 500 );
		}
	}

	function closeGallery( i ) {
		if ( i <= 5 ) {		//Make things faster
			setTimeout( function() {
				hideImage( gallery_images[i] );
			}, ( 5 - i ) * 100 );
		} else {
			hideImage( gallery_images[i] );
		}
	}

	function showImage( image ) {
		image.classList.add( 'visible' );
		image.style.marginTop = '0';
	}

	function hideImage( image ) {
		offset_top = image.offsetHeight;
		image.classList.remove( 'visible' );
		image.style.marginTop = - offset_top + 'px';
	}
}
/* globals TimeMachine, mobileGallery, smoothScroll, ajax, Ten4Slideshow */

import './vendor/polyfills/eventlistener.min.js';
import './vendor/polyfills/classlist.min.js';

import './vendor/ajax.min.js';
import imagesLoaded from 'imagesloaded';
import './vendor/time-machine.js';
import './vendor/slideshow.js';
import './vendor/lightbox.js';
import Flickity from 'flickity';
import Masonry from 'masonry-layout';
import './vendor/smoothscroll.min.js';
import { mobileGallery } from './vendor/mobilegallery.js';

( function() {

	//helpers
	window.slideshow_timer_ids = []; // Keep a reference to all slideshow timers so we can cancel them on page transition.

	function destroyPageScript() {
		window.Dust = null;
	}

	function preventDefault( e ) {
		e.preventDefault ? e.preventDefault() : e.returnValue = false;
	}

	function getUrlSegment( x ) {
		if ( ( window.location.href ).indexOf( 'ten4dev' ) !== -1 ) {	//If staging
			const path = window.location.pathname.split( 'dust/public/' )[1];
			return path.split( '/' )[x - 1];
		}
		return window.location.pathname.split( '/' )[x];
	}

	function getElStyle( el, property ) {
		return parseInt( window.getComputedStyle( el ).getPropertyValue( property ) );
	}

	function isElementInViewport( element ) {
		const rect = element.getBoundingClientRect();
		return (
			rect.top <= ( ( window.innerHeight || document.documentElement.clientHeight ) - 10 )
		);
	}

	function revealItem( item, timeOffset, imagesContainer ) {
		imagesLoaded( imagesContainer, () => {
			window.setTimeout( ( function( el ) {
				return function() {
					el.classList.remove( 'is-hidden' );
				};
			} )( item ), ( timeOffset * 0.9 ) * 100 );
		} );
	}

	window.runPageScript = function() {
		if ( typeof window.Dust === 'function' ) {
			window.Dust( {
				asyncFeed: asyncFeed,
				isElementInViewport: isElementInViewport,
				revealItem: revealItem,
				horizontalScrollOnClick: horizontalScrollOnClick,
				startSlideshow: startSlideshow,
				randomFadeIns: randomFadeIns,
				scrollFadeIns: scrollFadeIns,
				newsPageScript: newsPageScript,
				getOffsetTop: getOffsetTop,
				smoothScroll: smoothScroll,
				mobileGallery: mobileGallery,
				teamOverlay: teamOverlay,
				branchToggle: branchToggle,
				desktopGallery: desktopGallery,
				initMasonry: initMasonry,
				getUrlSegment: getUrlSegment
			} );
		}
		window.Dust = null;
	};

	function randomFadeIns() {
		//Random Fade-ins
		const items = document.querySelectorAll( '#js-grid .images-grid__item.is-hidden' );
		const container = document.querySelector( '#js-grid' );
		let random_no = Math.floor( Math.random() * items.length );
		const random_no_array = [];

		for ( let i = 0; i < items.length; i += 1 ) {
			while ( random_no_array.indexOf( random_no ) > -1 ) {
				random_no = Math.floor( Math.random() * items.length );
			}
			random_no_array.push( random_no );
		}

		for ( let y = 0; y < items.length; y += 1 ) {
			const index = random_no_array[y];
			revealItem( items[index], y, container );
		}
	}

	function scrollFadeIns( items ) {
		window.addEventListener( 'scroll', () => {
			const unrevealed_items = [];
			for ( let i = 0; i < items.length; i++ ) {
				if ( items[i].classList.contains( 'is-hidden' ) ) {
					unrevealed_items.push( items[i] );
				}
			}
			checkItemsInViewport( unrevealed_items );
		} );

		checkItemsInViewport( items );

		function checkItemsInViewport( items ) {
			for ( let i = 0; i < items.length; i += 1 ) {
				if ( isElementInViewport( items[i] ) ) {
					revealItem( items[i], i, items[i] );
					continue;
				}
			}
		}
	}

	function getOffsetTop( element ) {
		// Thanks to https://github.com/cferdinandi/smooth-scroll
		let offset = 0;
		if ( element.offsetParent ) {
			do {
				offset += element.offsetTop;
				element = element.offsetParent;
			} while ( element );
		}
		offset = Math.max( offset, 0 );
		return offset;
	}

	function getOffsetLeft( element ) {
		// Thanks to https://github.com/cferdinandi/smooth-scroll
		let offset = 0;
		if ( element.offsetParent ) {
			do {
				offset += element.offsetLeft;
				element = element.offsetParent;
			} while ( element );
		}
		offset = Math.max( offset, 0 );
		return offset;
	}

	function startSlideshow() {
		// Homepage Slideshow
		const element = document.querySelector( '.slideshow' );
		const slideshow = new Ten4Slideshow( element );
		const slides = element.querySelectorAll( '.slideshow__slide' );
		const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
		// const slideshow_pips = document.querySelectorAll( '.slideshow__project-categories__item' );
		let timer_id = null;

		changeTextColour( slides[0] );
		// changeCategory( slides[0] );

		resetInterval();

		slideshow.addCallback( ( index ) => {
			changeTextColour( slides[index] );
			// changeCategory( slides[index] );
		} );

		// for ( const i = 0; i < slideshow_pips.length; i += 1 ) {
		// 	slideshow_pips[i].addEventListener( 'click', handlePipClick( i ) );
		// }

		function changeTextColour( slide ) {
			const textColour = slide.getAttribute( 'data-text-colour' );
			if ( textColour === 'light' ) {
				document.body.classList.add( 'slideshow-text-white' );
			} else {
				document.body.classList.remove( 'slideshow-text-white' );
			}
		}
		// function changeCategory( slide ) {
		// 	const category = slide.getAttribute( 'data-category' );
		// 	const project_category_images = document.querySelectorAll( '.slideshow__project-categories__item' );
		// 	switch ( category ) {
		// 	case 'smallProject':
		// 		removeActive( project_category_images );
		// 		document.querySelector( '.slideshow__project-categories__item--small' ).classList.add( 'active' );
		// 		break;
		// 	case 'mediumProject':
		// 		removeActive( project_category_images );
		// 		document.querySelector( '.slideshow__project-categories__item--medium' ).classList.add( 'active' );
		// 		break;
		// 	case 'largeProject':
		// 		removeActive( project_category_images );
		// 		document.querySelector( '.slideshow__project-categories__item--large' ).classList.add( 'active' );
		// 		break;
		// 	case 'extraLargeProject':
		// 		removeActive( project_category_images );
		// 		document.querySelector( '.slideshow__project-categories__item--xlarge' ).classList.add( 'active' );
		// 	}
		// }
		// function removeActive( items ) {
		// 	for ( let i = 0; i < items.length; i++ ) {
		// 		items[i].classList.remove( 'active' );
		// 	}
		// }
		function resetInterval() {
			window.clearInterval( timer_id );
			timer_id = window.setInterval( () => {
				slideshow.slide();
			}, slide_interval );
			window.slideshow_timer_ids.push( timer_id );
		}
		// function handlePipClick( index ) {
		// 	return function() {
		// 		resetInterval();
		// 		slideshow.slideTo( index );
		// 	};
		// }
	}

	function asyncFeed() {
		// Async feeds
		const feed_containers = document.querySelectorAll( '.external-feed' );
		const spinners = document.querySelectorAll( '.spinner' );

		for ( let x = 0; x < spinners.length; x += 1 ) {
			spinners[x].classList.add( 'spinner--shown' );
		}

		for ( let i = 0; i < feed_containers.length; i += 1 ) {
			const url = feed_containers[i].getAttribute( 'data-feed-url' );
			if ( !url ) {
				continue;
			}
			ajax( {
				type: 'get',
				url: url,
				timeout: 8000,
				onSuccess: handleAjaxSuccess( feed_containers[i] ),
				onError: handleAjaxError( feed_containers[i] )
			} );
		}

		function handleAjaxSuccess( feed_container ) {
			return function( data ) {
				const append_container = feed_container.querySelector( '.external-feed__content' );
				const spinner = feed_container.querySelector( '.spinner' );
				spinner.classList.remove( 'spinner--shown' );
				window.setTimeout( () => {
					feed_container.classList.add( 'external-feed--loaded' );
					append_container.innerHTML = data;
				}, 300 );
			};
		}
		function handleAjaxError( feed_container ) {
			return function() {
				const append_container = feed_container.querySelector( '.external-feed__content' );
				const spinner = feed_container.querySelector( '.spinner' );
				spinner.classList.remove( 'spinner--shown' );
				window.setTimeout( () => {
					feed_container.classList.add( 'external-feed--loaded' );
					append_container.innerHTML = '<p class="p">Feed failed to load.</p>';
				}, 200 );
			};
		}
	}

	function horizontalScrollOnClick( news_content, slug ) {
		//News Listing Arrows
		const left = document.querySelector( '#js-left' );
		const right = document.querySelector( '#js-right' );
		let container_padding = getElStyle( news_content, 'padding-right' );
		let content_width = news_content.offsetWidth + 20 - container_padding;

		news_content.scrollTop = 0;
		news_content.scrollLeft = 0;

		const window_width = window.innerWidth;
		window.addEventListener( 'resize', () => {
			//Check for only horizontal resize
			const new_window_width = window.innerWidth;
			if ( window_width !== new_window_width ) {
				news_content.scrollTop = 0;
				news_content.scrollLeft = 0;
				container_padding = getElStyle( news_content, 'padding-right' );
				content_width = news_content.offsetWidth + 20 - container_padding;
			}
		} );

		if ( slug.length > 0 ) {
			//Scroll to entry if slug defined
			const news_post = document.querySelector( '.js-news-entry[data-slug="' + slug + '"]' );
			const news_post_pos = getOffsetLeft( news_post ) - container_padding;
			window.setTimeout( () => {
				smoothScroll( news_post_pos, 0, 0, 1 );
			}, 180 );
		}

		left.addEventListener( 'click', () => {
			const pos_x = news_content.scrollLeft;
			const pos_y = news_content.scrollTop;

			const limit = pos_x - content_width <= 0 ? 0 : pos_x - content_width;

			smoothScroll( limit, pos_x, pos_y, -1 );
		} );

		right.addEventListener( 'click', () => {
			const pos_x = news_content.scrollLeft;
			const pos_y = news_content.scrollTop;

			const limit = pos_x + content_width >= news_content.scrollWidth - news_content.clientWidth ? news_content.scrollWidth - news_content.clientWidth : pos_x + content_width;

			smoothScroll( limit, pos_x, pos_y, 1 );
		} );

		function smoothScroll( limit, pos_x, pos_y, direction ) {
			const scroll_int = window.setInterval( () => {
				const condition = direction > 0 ? pos_x <= limit : pos_x >= limit;
				if ( condition ) {
					news_content.scrollTop = pos_y;
					news_content.scrollLeft = pos_x;
					pos_x = direction > 0 ? pos_x + 15 : pos_x - 15;
				} else {
					window.clearInterval( scroll_int );
					pos_x = limit;
					news_content.scrollTop = pos_y;
					news_content.scrollLeft = pos_x;
				}
			}, 3 );
		}
	}

	function newsPageScript( slug ) {
		//News JavaScript
		const news_content = document.querySelector( '.news-wrapper' );

		if ( !news_content ) {
			return;
		}
		asyncFeed();
		horizontalScrollOnClick( news_content, slug );
	}

	function teamOverlay() {
		//Team Overlays
		const member_links = document.querySelectorAll( '.js-overlay-trigger' );
		const append_container = document.querySelector( '.team-overlay__content' );
		const close = document.querySelector( '.team-overlay__close' );
		const current_url = window.location.href;

		if ( !append_container || !( window.history && window.history.pushState ) ) {
			return;
		}

		for ( let i = 0; i < member_links.length; i++ ) {
			handleLinkClick( member_links[i] );
		}
		//Close Overlay
		close.addEventListener( 'click', () => {
			closeOverlay();
		} );

		function closeOverlay() {
			document.body.classList.remove( 'overlay-open' );
			window.setTimeout( () => {
				append_container.innerHTML = '';
			}, 200 );
			if ( window.history.pushState ) {
				window.history.pushState( null, null, current_url );
			}
		}

		function handleLinkClick( link ) {
			link.addEventListener( 'click', function( e ) {
				preventDefault( e );
				const url = link.getAttribute( 'href' );
				const overlay_type = this.getAttribute( 'data-overlay-type' );
				window.history.pushState( null, null, url );
				handleStateChange( overlay_type );
			} );
		}

		function handleStateChange( overlay_type ) {
			const target_url = window.location.href + '?ajax=true&request=' + overlay_type;
			document.body.classList.add( 'team-overlay-loading' );
			document.body.classList.add( 'overlay-open' );

			ajax( {
				type: 'get',
				url: target_url,
				data: {},
				timeout: 5000,
				onSuccess: handleAjaxSuccess(),
				onError: handleAjaxError()
			} );
		}

		function handleAjaxSuccess() {
			return function( data ) {
				document.body.classList.remove( 'team-overlay-loading' );
				document.querySelector( '.team-overlay' ).blur();
				append_container.innerHTML = data;
			};
		}
		function handleAjaxError() {
			return function() {
				append_container.innerHTML = 'Couldn\'t retrieve data';
			};
		}
	}

	function branchToggle() {
		//Branch clicks
		const append_container = document.querySelector( '#js-branch-content' );
		const branch_nav = document.querySelectorAll( '.branch-nav__item a' );
		const loader = document.querySelector( '.branch-loader' );

		if ( !append_container || !( window.history && window.history.pushState ) ) {
			return;
		}

		for ( let i = 0; i < branch_nav.length; i++ ) {
			handleClick( branch_nav[i] );
		}

		function handleClick( branch_nav_item ) {
			branch_nav_item.addEventListener( 'click', function( e ) {
				preventDefault( e );

				const url = this.href;
				const selected = document.querySelector( '.branch-nav__item .selected' );

				selected.classList.remove( 'selected' );
				this.classList.add( 'selected' );

				window.history.pushState( null, null, url );
				handleStateChange( selected );
			} );
		}

		function handleStateChange() {
			const url = window.location.href + '?ajax=true&request=branch';
			loader.classList.remove( 'hidden' );
			append_container.classList.add( 'is-hidden' );
			ajax( {
				type: 'get',
				url: url,
				data: {},
				timeout: 5000,
				onSuccess: handleAjaxSuccess(),
				onError: handleAjaxError()
			} );
		}

		function handleAjaxSuccess() {
			return function( data ) {
				append_container.classList.remove( 'is-hidden' );
				append_container.innerHTML = data;
				loader.classList.add( 'hidden' );
				scrollFadeIns( append_container.querySelectorAll( '.team-member.is-hidden' ) );
				teamOverlay();
				window.runPageScript();
			};
		}

		function handleAjaxError() {
			return function() {
				append_container.classList.remove( 'is-hidden' );
				append_container.innerHTML = 'There was a problem retrieving content.';
				loader.classList.add( 'hidden' );
				window.runPageScript();
			};
		}
	}

	function desktopGallery() {
		const images = document.querySelectorAll( '.project-gallery img' );
		const gallery = document.querySelector( '.project-gallery' );
		let gallery_height = document.querySelector( '.project-gallery' ).offsetHeight;

		initFlickity();
		toggleArrows();

		for ( let i = 0; i < images.length; i++ ) {
			fitImages( images[i] );
		}

		function fitImages( image ) {
			image.style.height = gallery_height + 'px';
			window.addEventListener( 'resize', () => {
				if ( !document.querySelector( '.project-gallery' ) ) {
					return;
				}
				gallery_height = document.querySelector( '.project-gallery' ).offsetHeight;
				image.style.height = gallery_height + 'px';
			} );
		}

		function initFlickity() {
			new Flickity( gallery, {
				cellAlign: 'left',
				contain: true,
				percentPosition: false,
				lazyLoad: true,
				imagesLoaded: true,
		} );
		}

		function toggleArrows() {
			const prev_arrow = document.querySelector( '.flickity-prev-next-button.previous' );
			const next_arrow = document.querySelector( '.flickity-prev-next-button.next' );

			gallery.addEventListener( 'mousemove', ( e ) => {
				const pos_x = e.clientX;
				if ( pos_x < ( window.innerWidth / 100 ) * 25 ) {
					prev_arrow.classList.add( 'visible' );
					next_arrow.classList.remove( 'visible' );
					return;
				}
				prev_arrow.classList.remove( 'visible' );
				next_arrow.classList.add( 'visible' );
			} );
		}
	}

	function initMasonry() {
		const elem = document.querySelector( '.masonry' );
		if ( !elem ) {
			return;
		}
		const imgLoad = imagesLoaded( elem );
		const masonry = new Masonry( elem, {
			itemSelector: '.masonry__item',
			transitionDuration: 0
		} );
		imgLoad.on( 'progress', () => {
			masonry.layout();
		} );

		return masonry;
	}

	( function() {
		// Cookie notice
		const cookie_notice = document.querySelector( '.cookie-notice' );
		if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
			document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000';
			document.body.classList.add( 'show-cookie-notice' );
			document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
				cookie_notice.style.marginTop = - cookie_notice.offsetHeight + 'px';
				window.setTimeout( () => {
					document.body.classList.remove( 'show-cookie-notice' );
				}, 200 );
			} );
		}
	} )();

	( function() {
		//Mobile Nav Toggle
		const toggle = document.querySelector( '.mobile-nav-toggle' );
		toggle.addEventListener( 'click', () => {
			document.body.classList.toggle( 'nav-open' );
			document.body.classList.remove( 'mobile-search-open' );
		} );
	} )();

	( function() {
		//Sticky Nav
		const header = document.querySelector( '.header' );
		let offset_top = getElStyle( header, 'margin-top' );
		let header_height = header.offsetHeight + offset_top * 2;
		let previous_scroll_top = 0;

		let window_width = window.innerWidth;
		window.addEventListener( 'resize', () => {
			const new_window_width = window.innerWidth;
			if ( window_width !== new_window_width ) {
				header.classList.remove( 'header--sticky' );
				offset_top = getElStyle( header, 'margin-top' );
				header_height = header.offsetHeight + offset_top * 2;
			}
			window_width = new_window_width;
		} );

		document.addEventListener( 'scroll', toggleStickiness );

		function toggleStickiness() {
			const scroll_top = window.pageYOffset || document.documentElement.scrollTop;
			if ( scroll_top > header_height ) {	//when header is out of view, stick hidden nav
				header.classList.add( 'header--sticky' );
				document.body.style.paddingTop = header_height + 'px';
			}
			if ( previous_scroll_top > scroll_top ) {	//when scroll up
				if ( scroll_top >= offset_top ) {	//Reveal sticky nav
					header.classList.add( 'reveal' );
				} else {	//Remove sticky nav
					header.classList.remove( 'header--sticky' );
					document.body.style.paddingTop = 0;
				}
			} else {	//when scroll down, hide sticky nav
				header.classList.remove( 'reveal' );
			}
			previous_scroll_top = scroll_top;
		}
	} )();

	( function() {
		//Search
		const search_open = document.querySelector( '#js-search-open' );
		const search_close = document.querySelector( '.search-overlay__close' );
		const search_input = document.querySelector( '.search-overlay input' );

		search_open.addEventListener( 'click', () => {
			document.body.classList.add( 'search-open' );
			search_input.focus();
			window.addEventListener( 'resize', () => {
				search_input.scrollIntoView();
				search_input.scrollIntoViewIfNeeded();
			} );
		} );
		search_close.addEventListener( 'click', () => {
			document.body.classList.remove( 'search-open' );
		} );
	} )();

	( function() {
		//Time Machine
		const ajax_receptacle = document.querySelector( '#ajax-receptacle' );
		const header = document.querySelector( '.header' );
		const search_form = document.querySelector( '.search-overlay__form' );

		const time_machine = TimeMachine( {
			site_root: ajax_receptacle.getAttribute( 'data-tm-site-root' ),
			ajax_receptacle: ajax_receptacle,
			state_change_selector: '.tm-a',
			metadata_element_selector: '#ajax-content',
			nav_items: document.querySelectorAll( '.nav li a' ),
			nav_selected_class: 'selected',
			title_suffix: ajax_receptacle.getAttribute( 'data-tm-title-suffix' ),
			remove_trailing_slash: true,
			defer_page_load: true,
			debug: false,
			beforeNewPageLoad: function( loadPage ) {
				smoothScroll.scroll( 0, 200 );
				document.documentElement.focus();
				destroyPageScript();

				// Clear any existing slideshow timers so they don't keep running in the background.
				for ( let i = 0; i < window.slideshow_timer_ids.length; i++ ) {
					window.clearInterval( window.slideshow_timer_ids[i] );
				}

				if ( document.body.classList.contains( 'slideshow-text-white' ) ) {
					removeClasses( document.body, [ 'slideshow-text-white' ] );
				}
				removeClasses( document.body, [ 'project-size-filters-open', 'project-details-open', 'nav-open', 'overlay-open', 'mobile-search-open', 'search-open' ] );
				document.body.classList.add( 'loading-page' );
				search_form.querySelector( 'input' ).value = '';

				loadPage();
			},
			// afterNewPageLoad: function( page_data ) {
			afterNewPageLoad: function() {
				header.classList.remove( 'header--top' );
				imagesLoaded( document.body, () => {
					document.body.classList.remove( 'loading-page' );
					window.runPageScript();
				} );
			}
		} );

		search_form.addEventListener( 'submit', ( e ) => {
			if ( !( window.history && window.history.pushState ) ) {
				return;
			}
			const url = search_form.getAttribute( 'action' );
			const query = search_form.querySelector( 'input' ).value;
			preventDefault( e );
			time_machine.pushStateChange( url + '?q=' + query );
		} );

		//helpers
		function removeClasses( el, classes ) {
			for ( let i = 0; i < classes.length; i++ ) {
				el.classList.remove( classes[i] );
			}
		}
	} )();

	window.runPageScript();

} )();